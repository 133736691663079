exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-english-js": () => import("./../../../src/pages/english.js" /* webpackChunkName: "component---src-pages-english-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-dla-firm-js": () => import("./../../../src/pages/oferta-dla-firm.js" /* webpackChunkName: "component---src-pages-oferta-dla-firm-js" */),
  "component---src-pages-oferta-duety-js": () => import("./../../../src/pages/oferta-duety.js" /* webpackChunkName: "component---src-pages-oferta-duety-js" */),
  "component---src-pages-oferta-indywidualna-js": () => import("./../../../src/pages/oferta-indywidualna.js" /* webpackChunkName: "component---src-pages-oferta-indywidualna-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-pierwsza-wizyta-js": () => import("./../../../src/pages/pierwsza-wizyta.js" /* webpackChunkName: "component---src-pages-pierwsza-wizyta-js" */),
  "component---src-pages-warsztaty-dla-instruktorow-js": () => import("./../../../src/pages/warsztaty-dla-instruktorow.js" /* webpackChunkName: "component---src-pages-warsztaty-dla-instruktorow-js" */),
  "component---src-pages-zapisy-js": () => import("./../../../src/pages/zapisy.js" /* webpackChunkName: "component---src-pages-zapisy-js" */),
  "component---src-pages-zespol-alicja-js": () => import("./../../../src/pages/zespol-alicja.js" /* webpackChunkName: "component---src-pages-zespol-alicja-js" */),
  "component---src-pages-zespol-aneta-js": () => import("./../../../src/pages/zespol-aneta.js" /* webpackChunkName: "component---src-pages-zespol-aneta-js" */),
  "component---src-pages-zespol-anna-js": () => import("./../../../src/pages/zespol-anna.js" /* webpackChunkName: "component---src-pages-zespol-anna-js" */),
  "component---src-pages-zespol-daria-js": () => import("./../../../src/pages/zespol-daria.js" /* webpackChunkName: "component---src-pages-zespol-daria-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-pages-zespol-magda-js": () => import("./../../../src/pages/zespol-magda.js" /* webpackChunkName: "component---src-pages-zespol-magda-js" */),
  "component---src-pages-zespol-masha-js": () => import("./../../../src/pages/zespol-masha.js" /* webpackChunkName: "component---src-pages-zespol-masha-js" */),
  "component---src-pages-zespol-milosz-js": () => import("./../../../src/pages/zespol-milosz.js" /* webpackChunkName: "component---src-pages-zespol-milosz-js" */),
  "component---src-pages-zespol-monika-js": () => import("./../../../src/pages/zespol-monika.js" /* webpackChunkName: "component---src-pages-zespol-monika-js" */),
  "component---src-pages-zespol-paulina-js": () => import("./../../../src/pages/zespol-paulina.js" /* webpackChunkName: "component---src-pages-zespol-paulina-js" */)
}

